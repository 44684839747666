import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { doc, updateDoc } from 'firebase/firestore';
import { auth } from '../firebase';
import { db } from '../firebase';

const tiers = [
  {
    title: '무료',
    price: '0',
    description: '기본 기능을 체험해보세요',
    features: [
      '하루 5회 기본 시뮬레이션',
      '기본 AI 모델 사용',  
      '커뮤니티 읽기 접근',      
    ],
    buttonText: '무료로 시작하기',
    buttonVariant: 'outlined',
    action: 'navigate', // 새로운 속성 추가
    actionTarget: '/chat', // 새로운 속성 추가
  },
  {
    title: '프로',
    price: '5.9',
    description: '전문가를 위한 기능',
    features: [
      '하루 5회 기본 시뮬레이션',
      '100회 고급 시뮬레이션 이용권',
      '고급 AI 모델 사용',
      '커뮤니티 전체 접근',      
      '우선 지원',
    ],
    buttonText: '시작하기',
    buttonVariant: 'contained',
    action: 'paypal',
    actionTarget: '5.9'
  },
  {
    title: '기업',
    price: '문의',
    description: '기업 맞춤형 솔루션',
    features: [
      '맞춤형 AI 모델',
      '전용 API 접근',
      'SLA 보장',
      '전담 매니저',
      '기업 대시보드',
    ],
    buttonText: '문의하기',
    buttonVariant: 'outlined',
    action: 'email', // 새로운 속성 추가
    actionTarget: 'votesimulator@gmail.com', // 새로운 속성 추가
  },
];

function Pricing() {
  const navigate = useNavigate();
  const [paypalLoading, setPaypalLoading] = useState(false);

  const handleButtonClick = (action, target) => {
    switch (action) {
      case 'navigate':
        navigate(target);
        break;
      case 'email':
        window.location.href = `mailto:${target}`;
        break;
      case 'link':
        // 기본 링크 처리
        break;
      default:
        break;
    }
  };

  const handlePaypalSuccess = async (details) => {
    try {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const now = new Date();
      const expiryDate = new Date(now.setDate(now.getDate() + 30)); // 30일 후

      await updateDoc(userDocRef, {
        membershipType: 'Pro',
        premiumCredits: 100,
        membershipExpiry: expiryDate.toISOString(), // 만료일 추가
        lastPayment: {
          id: details.id,
          amount: details.purchase_units[0].amount.value,
          date: new Date().toISOString()
        }
      });
      
      alert('프로 멤버십 구매가 완료되었습니다!');
      navigate('/profile');
    } catch (error) {
      console.error('결제 처리 중 오류:', error);
      alert('결제 처리 중 오류가 발생했습니다.');
    }
  };

  const renderButton = (tier) => {
    if (tier.action === 'paypal') {
      if (!auth.currentUser) {
        return (
          <Button
            fullWidth
            variant="contained"
            sx={{ width: '80%' }}
            onClick={() => navigate('/login', { 
              state: { from: '/pricing' }  // 현재 페이지 정보 전달
            })}
          >
            로그인 후 결제 가능
          </Button>
        );
      }

      return (
        <PayPalScriptProvider options={{
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          currency: "USD"
        }}>
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: tier.actionTarget
                  }
                }]
              });
            }}
            onApprove={async (data, actions) => {
              setPaypalLoading(true);
              try {
                const details = await actions.order.capture();
                await handlePaypalSuccess(details);
              } catch (error) {
                console.error('PayPal 결제 오류:', error);
                alert('결제 처리 중 오류가 발생했습니다.');
              } finally {
                setPaypalLoading(false);
              }
            }}
            style={{
              layout: "horizontal",
              color: "blue",
              shape: "rect",
              label: "pay",
              height: 40,
              tagline: false
            }}
          />
        </PayPalScriptProvider>
      );
    }

    return (
      <Button
        fullWidth
        variant={tier.buttonVariant}
        sx={{ width: '80%' }}
        onClick={() => handleButtonClick(tier.action, tier.actionTarget)}
      >
        {tier.buttonText}
      </Button>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box textAlign="center" mb={6}>
        <Typography
          component="h1"
          variant="h2"
          color="text.primary"
          gutterBottom
        >
          요금제
        </Typography>
        <Typography variant="h5" color="text.secondary">
          필요한 만큼만 선택하세요
        </Typography>
      </Box>
      <Grid 
        container 
        spacing={4} 
        alignItems="flex-start"
        justifyContent="center"
      >
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === '프로' ? 12 : 12}
            md={4}
            sx={{
              maxWidth: {
                xs: '100%',
                sm: '600px',
                md: '33.333%'
              }
            }}
          >
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                ...(tier.title === '프로' && {
                  border: '2px solid primary.main',
                  transform: {
                    md: 'scale(1.05)',
                    xs: 'none'
                  }
                }),
              }}
            >
              <CardHeader
                title={tier.title}
                titleTypographyProps={{ align: 'center' }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700],
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h3" color="text.primary">
                    {tier.price === '문의' ? '문의' : `$${tier.price}`}
                  </Typography>
   
                </Box>
                <Typography
                  component="p"
                  variant="subtitle1"
                  align="center"
                  sx={{ fontStyle: 'italic' }}
                >
                  {tier.description}
                </Typography>
                <List>
                  {tier.features.map((feature) => (
                    <ListItem key={feature} sx={{ py: 1 }}>
                      <CheckIcon sx={{ mr: 1, color: 'success.main' }} />
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              <CardActions sx={{ mt: 'auto', justifyContent: 'center', pb: 3 }}>
                {renderButton(tier)}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Pricing;