import React, { useState, useEffect, useMemo, useRef } from 'react';
import { db, auth } from '../firebase';
import { collection, query, getDocs, orderBy, deleteDoc, doc, setDoc, updateDoc, where, limit, startAfter, addDoc } from 'firebase/firestore';
import { throttle } from 'lodash';
import {
  Box, Typography, Paper, Avatar, Button, Collapse, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, CircularProgress, TextField, MenuItem, Select, FormControl
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VoteVisualization from './VoteVisualization';

function Community() {
  const [sharedMessages, setSharedMessages] = useState([]);
  const [expandedAnswers, setExpandedAnswers] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [userLikes, setUserLikes] = useState({});
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // 검색 및 정렬 관련 상태
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchPage, setSearchPage] = useState(1);

  const searchRef = useRef('');
  const [sortOption, setSortOption] = useState('latest'); // 'latest' or 'likes'

  // 컴포넌트 상단에 새로운 상태 추가
  const [renderKey, setRenderKey] = useState(0);

  // 컴포넌트 상단에 추가
  const [messageKeys, setMessageKeys] = useState(new Map());

  useEffect(() => {
    loadSharedMessages(true);
    if (auth.currentUser) {
      loadUserLikes();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        loadUserLikes();
      } else {
        setUserLikes({});
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // 컴포넌트가 마운트될 때마다 새로운 키 맵 생성
    const newKeys = new Map();
    [...sharedMessages, ...searchResults].forEach(msg => {
      newKeys.set(msg.id, `${msg.id}-${Date.now()}-${Math.random()}`);
    });
    setMessageKeys(newKeys);
  }, [sharedMessages, searchResults]);

  // 무한 스크롤 이벤트 핸들러
  const throttledHandleScroll = useMemo(
    () =>
      throttle(() => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollPosition >= documentHeight * 0.8 && !isLoading && hasMore) {
          if (isSearching && searchRef.current?.value.trim()) {
            loadMoreSearchResults();
          } else {
            loadSharedMessages(false);
          }
        }
      }, 300),
    [isLoading, hasMore, isSearching]
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
      throttledHandleScroll.cancel();
    };
  }, [throttledHandleScroll]);

  const loadSharedMessages = async (isFirstLoad = false) => {
    if (isLoading || (!hasMore && !isFirstLoad)) return;

    try {
      setIsLoading(true);
      let q = query(
        collection(db, 'shared_messages'),
        sortOption === 'latest' 
          ? orderBy('sharedAt', 'desc')
          : orderBy('likeCount', 'desc'),
        limit(10)
      );

      if (lastDoc && !isFirstLoad) {
        q = query(q, startAfter(lastDoc));
      }

      const querySnapshot = await getDocs(q);
      const newMessages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        likeCount: doc.data().likeCount || 0,
      }));

      setSharedMessages((prev) => (isFirstLoad ? newMessages : [...prev, ...newMessages]));
      setSearchResults((prev) => (isFirstLoad ? newMessages : [...prev, ...newMessages]));
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === 10);
    } catch (error) {
      console.error('메시지 로드 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadUserLikes = async () => {
    if (!auth.currentUser) return;

    try {
      const likesRef = collection(db, 'likes');
      const q = query(likesRef, where('userId', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);

      const likes = {};
      querySnapshot.forEach((doc) => {
        likes[doc.data().messageId] = true;
      });

      setUserLikes(likes);
    } catch (error) {
      console.error('좋아요 정보 로드 실패:', error);
    }
  };

  const handleDeleteClick = (message) => {
    setMessageToDelete(message);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!messageToDelete || !auth.currentUser) return;
    try {
      if (messageToDelete.userId === auth.currentUser.uid) {
        await deleteDoc(doc(db, 'shared_messages', messageToDelete.id));
        setSharedMessages((prev) => prev.filter((msg) => msg.id !== messageToDelete.id));
        setSearchResults((prev) => prev.filter((msg) => msg.id !== messageToDelete.id)); // 검색 결과에서도 제거
        alert('메시지가 삭제되었습니다.');
      } else {
        alert('삭제 권한이 없습니다.');
      }
    } catch (error) {
      console.error('메시지 삭제 실패:', error);
      alert('메시지 삭제에 실패했습니다.');
    } finally {
      setDeleteDialogOpen(false);
      setMessageToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setMessageToDelete(null);
  };

  const handleLike = async (messageId) => {
    if (!auth.currentUser) {
      alert('좋아요를 누르려면 로그인이 필요합니다.');
      return;
    }

    if (!auth.currentUser.emailVerified) {
      alert('이메일 인증이 필요합니다. 받은편지함을 확인하여 이메일 인증을 완료해주세요.');
      return;
    }

    try {
      const likeId = `${messageId}_${auth.currentUser.uid}`;
      const likeRef = doc(db, 'likes', likeId);
      const messageRef = doc(db, 'shared_messages', messageId);

      const targetMsgInAll = (arr) => arr.find((m) => m.id === messageId);

      if (userLikes[messageId]) {
        // 좋아요 취소
        await deleteDoc(likeRef);
        const newLikeCount = (targetMsgInAll(sharedMessages)?.likeCount || 1) - 1;
        await updateDoc(messageRef, {
          likeCount: newLikeCount,
        });
        setUserLikes((prev) => {
          const newLikes = { ...prev };
          delete newLikes[messageId];
          return newLikes;
        });
        setSharedMessages((prev) =>
          prev.map((msg) => (msg.id === messageId ? { ...msg, likeCount: newLikeCount } : msg))
        );
        setSearchResults((prev) =>
          prev.map((msg) => (msg.id === messageId ? { ...msg, likeCount: newLikeCount } : msg))
        );
      } else {
        // 좋아요 추가
        const newLikeCount = (targetMsgInAll(sharedMessages)?.likeCount || 0) + 1;
        await setDoc(likeRef, {
          userId: auth.currentUser.uid,
          messageId: messageId,
          createdAt: new Date(),
        });
        await updateDoc(messageRef, {
          likeCount: newLikeCount,
        });
        setUserLikes((prev) => ({
          ...prev,
          [messageId]: true,
        }));
        setSharedMessages((prev) =>
          prev.map((msg) => (msg.id === messageId ? { ...msg, likeCount: newLikeCount } : msg))
        );
        setSearchResults((prev) =>
          prev.map((msg) => (msg.id === messageId ? { ...msg, likeCount: newLikeCount } : msg))
        );
      }
    } catch (error) {
      console.error('좋아요 처리 실패:', error);
      alert('좋아요 처리에 실패했습니다.');
    }
  };

  const handleSearch = async () => {
    const term = searchRef.current?.value.trim().toLowerCase() || '';
    setIsSearching(!!term);
    
    try {
      setIsLoading(true);
      
      if (term) {
        let q = query(
          collection(db, 'shared_messages'),
          where('searchKeywords', 'array-contains', term),
          sortOption === 'latest' 
            ? orderBy('sharedAt', 'desc')
            : orderBy('likeCount', 'desc'),
          limit(10)
        );

        const querySnapshot = await getDocs(q);
        const messages = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          likeCount: doc.data().likeCount || 0
        }));

        setSearchResults(messages);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === 10);
      } else {
        // 검색어가 없을 때는 loadSharedMessages 재사용
        await loadSharedMessages(true);
      }
    } catch (error) {
      console.error('검색 실패:', error);
      alert('검색 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreSearchResults = async () => {
    if (isLoading || !hasMore) return;
    
    try {
      setIsLoading(true);
      const term = searchRef.current?.value.trim().toLowerCase();
      
      let q = query(
        collection(db, 'shared_messages'),
        where('searchKeywords', 'array-contains', term),
        sortOption === 'latest' 
          ? orderBy('sharedAt', 'desc')
          : orderBy('likeCount', 'desc'),
        startAfter(lastDoc),
        limit(10)
      );

      const querySnapshot = await getDocs(q);
      const newMessages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setSearchResults(prev => [...prev, ...newMessages]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === 10);
    } catch (error) {
      console.error('추가 메시지 로드 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // 검색 모드일 때 보여줄 메시지들
  const displayedMessages = isSearching ? searchResults : sharedMessages;

  // 메시지 렌더링 부분 수정
  const renderMessage = (message, context) => (
    <Paper 
      key={messageKeys.get(message.id) || `${message.id}-${Date.now()}-${Math.random()}`}
      sx={{ p: 3, mb: 2 }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar sx={{ mr: 2 }}>
          {(message.userDisplayName || message.userEmail.split('@')[0])[0].toUpperCase()}
        </Avatar>
        <Box sx={{ flex: 1 }}>
          <Typography variant="body2" color="text.secondary">
            공유자: {message.userDisplayName || message.userEmail.split('@')[0]}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            공유일: {new Date(message.sharedAt).toLocaleString()}
          </Typography>
        </Box>

        {message.userId === auth.currentUser?.uid && (
          <IconButton
            onClick={() => handleDeleteClick(message)}
            size="small"
            sx={{ '&:hover': { color: 'error.main' } }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <IconButton
            onClick={() => handleLike(message.id)}
            color={userLikes[message.id] ? 'error' : 'default'}
          >
            {userLikes[message.id] ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
          <Typography variant="body2">{message.likeCount || 0}</Typography>
        </Box>
      </Box>

      <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1, whiteSpace: 'pre-wrap' }}>
        질문: {message.question}
      </Typography>

      <Box sx={{ borderTop: '1px solid #e0e0e0', my: 2 }} />

      {message.isVoteSimulation && (
        <Box sx={{ width: '100%', minHeight: '300px', overflowX: 'auto', mb: 3 }}>
          <VoteVisualization gptResponse={message.answer} options={message.simulationOptions || []}
          agenda={message.question.match(/"([^"]+)"/)?.[1] || ''} // 추가된 부분
          displayName={message.userDisplayName || message.userEmail.split('@')[0]}
           />
        </Box>
      )}

      <Button onClick={() => setExpandedAnswers((prev) => ({ ...prev, [message.id]: !prev[message.id] }))}>
        {expandedAnswers[message.id] ? '답변 접기 ▼' : '답변 보기 ▶'}
      </Button>
      <Collapse in={expandedAnswers[message.id]} timeout="auto">
        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
          답변: {message.answer}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
          <Button 
            variant="outlined"
            onClick={() => {
              navigator.clipboard.writeText(message.answer);
              alert('복사되었습니다!');
            }}
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main',
              '&:hover': {
                borderColor: 'primary.dark',
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            복사하기
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );

  return (
    <Box
      sx={{
        maxWidth: 800,
        margin: 'auto',
        mt: 5,
        p: 3,
      }}
    >
      <Typography variant="h4" gutterBottom>
        커뮤니티
      </Typography>
 
      <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
  <Box sx={{ position: 'relative', flex: 1 }}>
    <TextField
      fullWidth
      size="small"
      placeholder="메시지 검색..."
      inputRef={searchRef}
      defaultValue=""
      inputProps={{
        style: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingRight: '70px', // Select의 너비와 동일하게 설정
        },
      }}
    />
    <FormControl
      size="small"
      sx={{
        position: 'absolute',
        right: 0, // 오른쪽 끝에 딱 붙게 설정
        top: '50%',
        transform: 'translateY(-50%)',
        minWidth: '70px',
      }}
    >
      <Select
        value={sortOption}
        onChange={(e) => setSortOption(e.target.value)}
        displayEmpty
      >
        <MenuItem value="latest">최신순</MenuItem>
        <MenuItem value="likes">좋아요순</MenuItem>
      </Select>
    </FormControl>
  </Box>
  <Button variant="outlined" onClick={handleSearch}>
    검색
  </Button>
</Box>


      {isSearching ? (
        displayedMessages.length === 0 ? (
          <Typography sx={{ textAlign: 'center', color: 'text.secondary', my: 2 }}>
            검색 결과가 없습니다.
          </Typography>
        ) : (
          <>
            {displayedMessages.map(message => renderMessage(message, 'search'))}
            {!hasMore && displayedMessages.length > 0 && (
              <Typography sx={{ textAlign: 'center', py: 2, color: 'text.secondary' }}>
                모든 메시지를 불러왔습니다.
              </Typography>
            )}
          </>
        )
      ) : (
        <>
          {sharedMessages.map(message => renderMessage(message, 'shared'))}
          {!hasMore && sharedMessages.length > 0 && (
            <Typography sx={{ textAlign: 'center', py: 2, color: 'text.secondary' }}>
              모든 메시지를 불러왔습니다.
            </Typography>
          )}
        </>
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        disableRestoreFocus
        keepMounted
        onBackdropClick={() => {
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
          handleDeleteCancel();
        }}
      >
        <DialogTitle id="delete-dialog-title">메시지 삭제 확인</DialogTitle>
        <DialogContent>
          <DialogContentText>정말로 이 메시지를 삭제하시겠습니까?</DialogContentText>
          <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
            {messageToDelete?.question}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>취소</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained" autoFocus>
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Community;