import React from 'react';
import { Box, Typography } from '@mui/material';
import TokenIcon from '@mui/icons-material/Token';
import DiamondIcon from '@mui/icons-material/Diamond';

function DailyUsageCoins({ remainingUses, maxUses, isPro, premiumCredits }) {
  return (
    <Box sx={{ 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: { xs: 4, sm: 3 },
      padding: '8px 16px',
      borderRadius: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
      border: '1px solid',
      borderColor: 'divider',
      width: 'auto'
    }}>
      {/* 무료 생성 횟수 표시를 먼저 배치 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 0.5
      }}>
        <TokenIcon 
          sx={{ 
            color: remainingUses > 0 ? 'primary.main' : 'grey.300',
            fontSize: '1.5rem' 
          }}
        />
        <Typography variant="body1" sx={{ 
          fontWeight: 'bold', 
          color: remainingUses > 0 ? 'primary.main' : 'grey.300'
        }}>
          × {remainingUses}
        </Typography>
      </Box>

      {/* 프리미엄 크레딧 표시를 나중에 배치 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 0.5 
      }}>
        <DiamondIcon sx={{ 
          color: premiumCredits > 0 ? 'primary.main' : 'grey.300',
          fontSize: '1.5rem' 
        }} />
        <Typography variant="body1" sx={{ 
          fontWeight: 'bold', 
          color: premiumCredits > 0 ? 'primary.main' : 'grey.300'
        }}>
          × {premiumCredits}
        </Typography>
      </Box>
    </Box>
  );
}

export default DailyUsageCoins;