import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { auth, db } from '../firebase';
import { collection, addDoc, query, where, getDocs, deleteDoc, doc, startAfter, limit, orderBy, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import VoteVisualization from './VoteVisualization';
import { throttle } from 'lodash';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Collapse,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DailyUsageCoins from './DailyUsageCoins';
import PremiumDialog from './PremiumDialog';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Chat() {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedAnswers, setExpandedAnswers] = useState({});
  const [step, setStep] = useState(1);
  const [agenda, setAgenda] = useState('');
  const [optionCount, setOptionCount] = useState('');
  const [options, setOptions] = useState([]);
  const [peopleCount, setPeopleCount] = useState('');
  const [people, setPeople] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [sourceIndex, setSourceIndex] = useState(null);
  const [lastCheckedIndex, setLastCheckedIndex] = useState(null);
  const [isSimulationReady, setIsSimulationReady] = useState(false);
  const [generatedPrompt, setGeneratedPrompt] = useState('');
  const [isPeopleLoading, setIsPeopleLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [isSharedMessage, setIsSharedMessage] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [remainingUses, setRemainingUses] = useState(5);
  const [lastUsageDate, setLastUsageDate] = useState(null);
  const [showPremiumDialog, setShowPremiumDialog] = useState(false);
  const [isPro, setIsPro] = useState(false);
  const [maxDailyUses, setMaxDailyUses] = useState(5);

  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchPage, setSearchPage] = useState(1);
  const [searchHasMore, setSearchHasMore] = useState(false);

  const agendaRef = useRef();
  const optionCountRef = useRef();
  const optionsRef = useRef([]);
  const peopleCountRef = useRef();
  const searchRef = useRef(); // 검색어 ref

  const [usePremium, setUsePremium] = useState(false);
  const [premiumCredits, setPremiumCredits] = useState(0);

  const [isSimulationLoading, setIsSimulationLoading] = useState(false);  // 시뮬레이션 로딩용
  const [isSearchLoading, setIsSearchLoading] = useState(false);  // 검색 로딩용

  const navigate = useNavigate();

  const [currentSearchTerm, setCurrentSearchTerm] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        // 이메일 인증 확인
        if (!currentUser.emailVerified) {
          setMessages([]);
          return;
        }
        // 토큰 갱신
        await currentUser.getIdToken(true);
        loadHistory(true);
      } else {
        setMessages([]);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const loadUserData = async () => {
      if (!auth.currentUser || !auth.currentUser.emailVerified) return;

      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setPremiumCredits(userData.premiumCredits || 0);
        
        // 멤버십 만료 체크
        const now = new Date();
        const expiry = userData.membershipExpiry ? new Date(userData.membershipExpiry) : null;
        const isProActive = userData.membershipType === 'Pro' && expiry && now < expiry;

        if (!isProActive && userData.membershipType === 'Pro') {
          // 만료된 경우 Free로 변경
          await updateDoc(userDocRef, {
            membershipType: 'Free',
            premiumCredits: 0
          });
          setIsPro(false);
        } else {
          setIsPro(isProActive);
        }

        // 24시간 이내 사용량 계산
        const usageHistory = userData.usageHistory || [];
        const recentUsage = usageHistory.filter(usage => {
          const usageTime = new Date(usage);
          return (now - usageTime) < 24 * 60 * 60 * 1000;
        });

        setRemainingUses(5 - recentUsage.length);
      } else {
        // 새 사용자의 경우 문서 생성
        await setDoc(userDocRef, {
          membershipType: 'Free',
          premiumCredits: 0,
          usageHistory: []
        });
        setRemainingUses(5);
        setIsPro(false);
      }
    };

    if (auth.currentUser) {
      loadUserData();
    }
  }, [auth.currentUser]);

  const throttledHandleScroll = useMemo(
    () => throttle(() => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= documentHeight * 0.8 && !isLoading && hasMore) {
        if (isSearching && searchRef.current?.value.trim()) {
          loadMoreSearchResults();
        } else {
          loadHistory();
        }
      }
    }, 300),
    [isLoading, hasMore, isSearching]
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
      throttledHandleScroll.cancel();
    };
  }, [throttledHandleScroll]);

  const loadHistory = async (isFirstLoad = false) => {
    if (!auth.currentUser || isLoading || (!hasMore && !isFirstLoad)) return;

    try {
      setIsLoading(true);
      let q = query(
        collection(db, 'messages'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('timestamp', 'desc'),
        limit(10)
      );

      if (lastDoc && !isFirstLoad) {
        q = query(q, startAfter(lastDoc));
      }

      const querySnapshot = await getDocs(q);
      const newMessages = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        documentId: doc.id
      }));

      setMessages(prev => isFirstLoad ? newMessages : [...prev, ...newMessages]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === 10);
    } catch (error) {
      console.error('히스토리 로드 실패:', error);
      alert('대화 내역을 불러오는데 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreSearchResults = async () => {
    if (isLoading || !hasMore) return;
    
    try {
      setIsLoading(true);
      let q;

      if (currentSearchTerm && lastDoc) {
        q = query(
          collection(db, 'messages'),
          where('userId', '==', auth.currentUser.uid),
          where('searchKeywords', 'array-contains', currentSearchTerm),
          orderBy('timestamp', 'desc'),
          startAfter(lastDoc),
          limit(10)
        );
      }

      const querySnapshot = await getDocs(q);
      const newMessages = querySnapshot.docs.map(doc => ({
        documentId: doc.id,
        ...doc.data()
      }));

      setSearchResults(prev => [...prev, ...newMessages]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === 10);
    } catch (error) {
      console.error('추가 메시지 로드 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!auth.currentUser?.emailVerified) {
      alert('이메일 인증이 필요합니다. 받은편지함을 확인해주세요.');
      return;
    }
    if (!input.trim() || isSimulationLoading || !isSimulationReady) return;

    setIsSimulationLoading(true);

    try {
      // 토큰 가져오기
      const token = await auth.currentUser.getIdToken();

      const response = await axios.post(
        "https://us-central1-votesim-a2fba.cloudfunctions.net/chatGptProxy",
        {
          agenda,
          peopleCount,
          people,
          options,
          isPremium: usePremium
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // 응답 처리
      const gptResponse = response.data.choices[0].message.content;

      const messageData = {
        userId: auth.currentUser.uid,
        question: input,
        answer: gptResponse,
        timestamp: new Date().toISOString(),
        simulationOptions: [...options],
        isVoteSimulation: true,
        isPremium: usePremium,
        searchKeywords: generateSearchKeywords(input)
      };

      const docRef = await addDoc(collection(db, 'messages'), messageData);
      const newMessage = { ...messageData, documentId: docRef.id };

      setMessages(prev => [newMessage, ...prev]);

      // 사용량 업데이트 (일반/프리미엄 모두)
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      if (usePremium) {
        // 프리미엄 크레딧 업데이트
        setPremiumCredits(userData.premiumCredits || 0);
      } else {
        // 일반 사용량 업데이트
        const now = new Date();
        const usageHistory = userData.usageHistory || [];
        const recentUsage = usageHistory.filter(usage => {
          const usageTime = new Date(usage);
          return (now - usageTime) < 24 * 60 * 60 * 1000;
        });
        setRemainingUses(5 - recentUsage.length);
      }

    } catch (error) {
      if (error.response?.status === 429) {
        // 사용량 초과 에러 처리
        const nextAvailable = new Date(error.response.data.nextAvailableTime);
        alert(`일일 사용 한도를 초과했습니다.\n다음 사용 가능 시간: ${nextAvailable.toLocaleString()}`);
        setShowPremiumDialog(true);
      } else if (error.response?.status === 403) {
        if (error.response.data.membershipExpired) {
          alert('프리미엄 멤버십이 만료되었습니다.');
          navigate('/pricing');
        } else if (error.response.data.insufficientCredits) {
          alert('프리미엄 크레딧이 부족합니다.');
          navigate('/pricing');
        }
      } else {
        console.error('Error:', error);
        alert('메시지 전송 중 오류가 발생했습니다.');
      }
    } finally {
      setIsSimulationLoading(false);
    }
  };

  const handleStepEdit = (stepNumber) => {
    setStep(stepNumber);
    setIsSimulationReady(false);

    switch (stepNumber) {
      case 1:
        setOptionCount('');
        setOptions([]);
        setPeopleCount('');
        setPeople([]);
        break;
      case 2:
        setOptions([]);
        setPeopleCount('');
        setPeople([]);
        break;
      case 3:
        setPeopleCount('');
        setPeople([]);
        break;
      case 4:
        setPeople([]);
        break;
      default:
        break;
    }
  };

  const handleDeleteClick = async (message) => {
    const isShared = await checkIfMessageShared(message);
    setIsSharedMessage(isShared);
    setMessageToDelete(message);
    setDeleteDialogOpen(true);
  };

  const handleSearch = async () => {
    const term = searchRef.current?.value.trim().toLowerCase() || '';
    setIsSearching(!!term);
    setCurrentSearchTerm(term);
    
    try {
      setIsLoading(true);
      
      if (term) {
        // 검색 모드
        let q = query(
          collection(db, 'messages'),
          where('userId', '==', auth.currentUser.uid),
          where('searchKeywords', 'array-contains', term),
          orderBy('timestamp', 'desc'),
          limit(10)
        );

        const querySnapshot = await getDocs(q);
        const messages = querySnapshot.docs.map(doc => ({
          documentId: doc.id,
          ...doc.data()
        }));

        // 검색 시작할 때 초기화
        setSearchResults(messages);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === 10);
      } else {
        // 검색어가 없을 때는 일반 모드로 초기화
        setSearchResults([]);
        setLastDoc(null);
        await loadHistory(true);  // true를 전달하여 처음부터 다시 로드
      }
    } catch (error) {
      console.error('검색 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    setDeleteDialogOpen(false);
    setMessageToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (!messageToDelete) return;

    try {
      await deleteDoc(doc(db, 'messages', messageToDelete.documentId));

      if (isSharedMessage) {
        const q = query(
          collection(db, 'shared_messages'),
          where('userId', '==', auth.currentUser.uid),
          where('sharedId', '==', messageToDelete.documentId)
        );
        const querySnapshot = await getDocs(q);
        const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deletePromises);
      }

      setMessages(messages.filter(msg => msg.documentId !== messageToDelete.documentId));
      setDeleteDialogOpen(false);
      setMessageToDelete(null);
      setIsSharedMessage(false);
    } catch (error) {
      console.error('메시지 삭제 실패:', error);
      alert('메시지 삭제에 실패했습니다.');
    }
  };

  const generateRandomPerson = () => ({
    gender: 'random',
    age: 'random',
    job: 'random',
    mbti: 'random'
  });

  const checkIfMessageShared = async (message) => {
    try {
      const q = query(
        collection(db, 'shared_messages'),
        where('userId', '==', auth.currentUser.uid),
        where('sharedId', '==', message.documentId)
      );
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error('공유 상태 확인 실패:', error);
      return false;
    }
  };

  const handleShare = async (message) => {
    try {
      const isAlreadyShared = await checkIfMessageShared(message);

      if (isAlreadyShared) {
        alert('이미 커뮤니티에 공유된 메시지입니다.');
        return;
      }

      const sharedId = message.documentId;
      const userDisplayName = auth.currentUser.displayName || auth.currentUser.email.split('@')[0];
      
      // 검색 키워드에 작성자 정보 포함
      const searchKeywords = [
        ...generateSearchKeywords(message.question),
        userDisplayName.toLowerCase(),
        auth.currentUser.email.split('@')[0].toLowerCase()
      ];

      await addDoc(collection(db, 'shared_messages'), {
        sharedId,
        question: message.question,
        answer: message.answer,
        userEmail: auth.currentUser.email,
        userId: auth.currentUser.uid,
        userDisplayName,
        sharedAt: new Date().toISOString(),
        simulationOptions: message.simulationOptions || [],
        isVoteSimulation: true,
        searchKeywords: [...new Set(searchKeywords)] // 중복 제거
      });
      alert('커뮤니티에 공유되었습니다!');
    } catch (error) {
      console.error('공유 실패:', error);
      alert('공유 중 오류가 발생했습니다.');
    }
  };

  const handleCheckboxChange = (index, event) => {
    const isShiftPressed = event?.nativeEvent?.shiftKey;

    if (isShiftPressed && lastCheckedIndex !== null) {
      const start = Math.min(lastCheckedIndex, index);
      const end = Math.max(lastCheckedIndex, index);
      const rangeIndexes = Array.from(
        { length: end - start + 1 },
        (_, i) => start + i
      );

      const newSelected = Array.from(
        new Set([...selectedPeople, ...rangeIndexes])
      );

      setSelectedPeople(newSelected);
    } else {
      if (selectedPeople.length === 0) {
        setSelectedPeople([index]);
        setSourceIndex(index);
      } else if (index === sourceIndex) {
        setSelectedPeople([]);
        setSourceIndex(null);
      } else if (selectedPeople.includes(index)) {
        setSelectedPeople(selectedPeople.filter(i => i !== index));
      } else {
        setSelectedPeople([...selectedPeople, index]);
      }
    }

    setLastCheckedIndex(index);
  };

  const handleCopyAttributes = () => {
    if (sourceIndex !== null && selectedPeople.length > 1) {
      const sourcePerson = people[sourceIndex];
      const newPeople = [...people];
      selectedPeople.filter(index => index !== sourceIndex).forEach(index => {
        newPeople[index] = { ...sourcePerson };
      });
      setPeople(newPeople);

      setTimeout(() => {
        setSelectedPeople([]);
        setSourceIndex(null);
      }, 50);
    }
  };

  const handlePeopleSubmit = () => {
    setStep(6);
    setIsSimulationReady(true);
    const displayPrompt = `"${agenda}"라는 의제에 대해 다양한 성향을 가진 ${peopleCount}명의 사람들에게 물어보았습니다.`;
    setGeneratedPrompt(displayPrompt);
    setInput(displayPrompt);
  };

  const renderCompletedStep = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  의제
                </Typography>
                <Typography>{agenda}</Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleStepEdit(1)}
                sx={{ ml: 2 }}
              >
                수정
              </Button>
            </Box>
          </Paper>
        );
      case 2:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  답변 종류 수
                </Typography>
                <Typography>{optionCount}개</Typography>
              </Box>
              {usePremium && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleStepEdit(2)}
                  sx={{ ml: 2 }}
                >
                  수정
                </Button>
              )}
            </Box>
          </Paper>
        );
      case 3:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  답변 옵션
                </Typography>
                {options.map((option, index) => (
                  <Typography key={index}>
                    {index + 1}. {option}
                  </Typography>
                ))}
              </Box>
              {usePremium && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleStepEdit(3)}
                  sx={{ ml: 2 }}
                >
                  수정
                </Button>
              )}
            </Box>
          </Paper>
        );
      case 4:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  참여 인원 수
                </Typography>
                <Typography>{peopleCount}명</Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleStepEdit(4)}
                sx={{ ml: 2 }}
              >
                수정
              </Button>
            </Box>
          </Paper>
        );
      case 5:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  참여자 정보
                </Typography>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2, mb: 1 }}>
                  <Typography>성별</Typography>
                  <Typography>나이</Typography>
                  <Typography>직업</Typography>
                  <Typography>MBTI</Typography>
                </Box>
                <Box>
                  {people.map((person, index) => (
                    <Box key={index} sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(4, 1fr)',
                      gap: 2,
                      mb: 1,
                      '&:last-child': { mb: 0 }
                    }}>
                      <Typography>{person.gender}</Typography>
                      <Typography>{person.age === 'random' ? 'random' : `${person.age}세`}</Typography>
                      <Typography>{person.job}</Typography>
                      <Typography>{person.mbti}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleStepEdit(5)}
                sx={{ ml: 2 }}
              >
                수정
              </Button>
            </Box>
          </Paper>
        );
      default:
        return null;
    }
  };

  // 검색 모드일 때 보여줄 메시지들 (페이징 적용)
  const displayedSearchResults = isSearching ? searchResults : messages;

  // step 2와 3에서 일반 모드일 때의 기본값 설정
  useEffect(() => {
    if (!usePremium && step === 2) {
      setOptionCount('2');
      setOptions(['찬성', '반대']);
      setStep(4);  // 옵션 설정을 건너뛰고 바로 인원수 설정으로
    }
  }, [step, usePremium]);

  // 인원수 제한 설정
  const maxPeopleCount = usePremium ? 30 : 10;

  // 검색용 키워드 생성 함수 추가
  const generateSearchKeywords = (text) => {
    // 기존 텍스트에서 키워드 추출
    const normalized = text.toLowerCase();
    // 특수문자 제거, 공백으로 분리
    const words = normalized.replace(/[^\w\s가-힣]/g, ' ').split(/\s+/);
    
    // 사용자 정보 추가
    const userDisplayName = auth.currentUser?.displayName?.toLowerCase() || '';
    const userEmail = auth.currentUser?.email?.split('@')[0].toLowerCase() || '';
    
    // 중복 제거 및 사용자 정보 포함
    return [...new Set([...words, userDisplayName, userEmail])].filter(word => word);
  };

  useEffect(() => {
    // 프리미엄 모드 토글 시 모든 값 초기화
    setStep(1);
    setAgenda('');
    setOptionCount('');
    setOptions([]);
    setPeopleCount('');
    setPeople([]);
    setIsSimulationReady(false);
    setGeneratedPrompt('');
    setInput('');
  }, [usePremium]);

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', mt: 5, p: 3 }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        mb: 3
      }}>
        <Typography variant="h4">
          시뮬레이터
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {auth.currentUser?.email} 으로 로그인됨
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        mb: 3
      }}>
        <Box sx={{ 
          padding: '8px 16px',
          borderRadius: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          border: '1px solid',
          borderColor: 'divider',
          minWidth: 'fit-content'
        }}>
          <FormControlLabel
            control={
              <Switch
                checked={usePremium}
                onChange={(e) => {
                  if (!isPro) {
                    setShowPremiumDialog(true);
                    return;
                  }
                  setUsePremium(e.target.checked);
                }}
                color="primary"
              />
            }
            label={
              <Typography sx={{ 
                color: 'text.secondary',
                fontSize: '0.875rem'
              }}>
                {usePremium ? "프리미엄 모드" : "일반 모드"}
              </Typography>
            }
            sx={{ margin: 0 }}
          />
        </Box>

        <Box sx={{ width: 'auto' }}>
          <DailyUsageCoins
            remainingUses={remainingUses}
            maxUses={5}
            isPro={isPro}
            premiumCredits={premiumCredits}
          />
        </Box>
      </Box>

      {Array.from({ length: step - 1 }, (_, i) => i + 1).map(stepNum => (
        <div key={`completed-step-${stepNum}`}>
          {renderCompletedStep(stepNum)}
        </div>
      ))}

      {step === 1 && (
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            placeholder="의제 설정(예:인공지능은 규제가 필요한가요?)"
            inputRef={agendaRef}
            defaultValue={agenda}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            onClick={() => {
              const newAgenda = agendaRef.current?.value || '';
              if (newAgenda.trim()) {
                setAgenda(newAgenda);
                setStep(2);
              }
            }}
          >
            확인
          </Button>
        </Box>
      )}

      {step === 2 && usePremium && (
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            type="number"
            label="답변 종류 수 (최대 4개/입력이 없으면 찬:반으로 설정)"
            inputRef={optionCountRef}
            defaultValue={optionCount}
            slotProps={{
              min: 1,
              max: 4,
              inputMode: 'numeric',
              pattern: '[0-9]*',
              style: { textAlign: 'center' }
            }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            onClick={() => {
              const count = parseInt(optionCountRef.current?.value || '0');
              // 입력값이 없거나 0인 경우 기본값 설정
              if (!count) {
                setOptionCount('2');
                setOptions(['찬성', '반대']);
                setStep(4);  // 바로 인원수 설정으로 이동
              } else if (count > 0 && count <= 4) {
                setOptionCount(count.toString());
                setOptions(Array(count).fill(''));
                setStep(3);
              }
            }}
          >
            확인
          </Button>
        </Box>
      )}

      {step === 3 && usePremium && (
        <Box sx={{ mb: 3 }}>
          {Array(parseInt(optionCount)).fill(null).map((_, index) => (
            <TextField
              key={index}
              fullWidth
              label={`답변 ${index + 1}`}
              defaultValue={options[index]}
              inputRef={(el) => optionsRef.current[index] = el}
              sx={{ mb: 2 }}
            />
          ))}
          <Button
            variant="contained"
            onClick={() => {
              const newOptions = Array(parseInt(optionCount))
                .fill(null)
                .map((_, i) => optionsRef.current[i]?.value || '');

              if (newOptions.every(option => option.trim())) {
                setOptions(newOptions);
                setStep(4);
              }
            }}
          >
            확인
          </Button>
        </Box>
      )}

      {step === 4 && (
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            type="number"
            label={`참여 인원 수 (최대 ${maxPeopleCount}명)`}
            inputRef={peopleCountRef}
            defaultValue={peopleCount}
            slotProps={{
              min: 1,
              max: maxPeopleCount,
              inputMode: 'numeric',
              pattern: '[0-9]*',
              style: { textAlign: 'center' }
            }}
            sx={{ mb: 2 }}
          />
          {isPeopleLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                const count = parseInt(peopleCountRef.current?.value || '0');
                if (count > 0 && count <= maxPeopleCount) {
                  setIsPeopleLoading(true);
                  setTimeout(() => {
                    setPeopleCount(count.toString());
                    setPeople(Array(count).fill(null).map(() => generateRandomPerson()));
                    setStep(5);
                    setIsPeopleLoading(false);
                  }, 500);
                }
              }}
            >
              확인
            </Button>
          )}
        </Box>
      )}

      {step === 5 && (
        <Box>
          {selectedPeople.length > 1 && (
            <Box
              sx={{
                position: 'fixed',
                bottom: '2rem',
                right: '2rem',
                zIndex: 1000,
                animation: 'fadeIn 0.3s ease-in-out',
                '@keyframes fadeIn': {
                  '0%': {
                    opacity: 0,
                    transform: 'translateY(20px)'
                  },
                  '100%': {
                    opacity: 1,
                    transform: 'translateY(0)'
                  }
                }
              }}
            >
              <Button
                variant="contained"
                onClick={handleCopyAttributes}
                sx={{
                  boxShadow: 3,
                  borderRadius: '28px',
                  px: 3,
                  py: 1.5,
                  bgcolor: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.dark'
                  }
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>선택된 항목에 속성 복사하기</Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.55rem',
                      opacity: 0.8
                    }}
                  >
                    (Shift+체크박스)하시면 범위 선택이 가능합니다.
                  </Typography>
                </Box>
              </Button>
            </Box>
          )}
          <Paper sx={{ p: 2, mb: 2, bgcolor: 'primary.main', color: 'white' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '40px 50px repeat(4, 1fr)', gap: 2, alignItems: 'center' }}>
              <Box>No.</Box>
              <Box>선택</Box>
              <Typography>성별</Typography>
              <Typography>나이</Typography>
              <Typography>직업</Typography>
              <Typography>MBTI</Typography>
            </Box>
          </Paper>

          {people.map((person, index) => (
            <Paper key={index} sx={{ p: 2, mb: 2 }}>
              <Box sx={{ display: 'grid', gridTemplateColumns: '40px 50px repeat(4, 1fr)', gap: 2, alignItems: 'center' }}>
                <Typography sx={{ textAlign: 'center', fontWeight: 'medium', color: 'text.secondary' }}>
                  {index + 1}
                </Typography>
                <Checkbox
                  checked={selectedPeople.includes(index)}
                  onChange={(event) => handleCheckboxChange(index, event)}
                  sx={{
                    color: index === sourceIndex ? 'primary.main' : 'error.main',
                    '&.Mui-checked': {
                      color: index === sourceIndex ? 'primary.main' : 'error.main',
                    }
                  }}
                />

                <FormControl fullWidth>
                  <Select
                    value={person.gender}
                    onChange={(e) => {
                      const newPeople = [...people];
                      newPeople[index] = {
                        ...newPeople[index],
                        gender: e.target.value
                      };
                      setPeople(newPeople);
                    }}
                  >
                    <MenuItem value="random">랜덤</MenuItem>
                    <MenuItem value="남성">남성</MenuItem>
                    <MenuItem value="여성">여성</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <Select
                    value={person.age}
                    onChange={(e) => {
                      const newPeople = [...people];
                      newPeople[index] = {
                        ...newPeople[index],
                        age: e.target.value
                      };
                      setPeople(newPeople);
                    }}
                  >
                    <MenuItem value="random">랜덤</MenuItem>
                    {Array.from({ length: 81 }, (_, i) => i + 10).map(age => (
                      <MenuItem key={age} value={age}>{age}세</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <Select
                    value={person.job}
                    onChange={(e) => {
                      const newPeople = [...people];
                      newPeople[index] = {
                        ...newPeople[index],
                        job: e.target.value
                      };
                      setPeople(newPeople);
                    }}
                    disabled={!usePremium}
                  >
                    <MenuItem value="random">랜덤</MenuItem>
                    <MenuItem value="회사원">회사원</MenuItem>
                    <MenuItem value="학생">학생</MenuItem>
                    <MenuItem value="자영업자">자영업자</MenuItem>
                    <MenuItem value="공무원">공무원</MenuItem>
                    <MenuItem value="전문직">전문직</MenuItem>
                    <MenuItem value="일용직">일용직</MenuItem>
                    <MenuItem value="주부">주부</MenuItem>
                    <MenuItem value="무직">무직</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <Select
                    value={person.mbti}
                    onChange={(e) => {
                      const newPeople = [...people];
                      newPeople[index] = {
                        ...newPeople[index],
                        mbti: e.target.value
                      };
                      setPeople(newPeople);
                    }}
                    disabled={!usePremium}
                  >
                    <MenuItem value="random">랜덤</MenuItem>
                    {['INTJ', 'INTP', 'ENTJ', 'ENTP', 'INFJ', 'INFP', 'ENFJ', 'ENFP',
                      'ISTJ', 'ISFJ', 'ESTJ', 'ESFJ', 'ISTP', 'ISFP', 'ESTP', 'ESFP'
                    ].map(mbti => (
                      <MenuItem key={mbti} value={mbti}>{mbti}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Paper>
          ))}

          <Button
            variant="contained"
            onClick={handlePeopleSubmit}
            sx={{ mt: 2 }}
            fullWidth
          >
            확인
          </Button>
        </Box>
      )}

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          multiline
          rows={3}
          value={input}
          placeholder={
            !auth.currentUser
              ? "로그인 후 이용하실 수 있습니다"
              : isSimulationReady
                ? "시뮬레이션 결과를 확인하세요"
                : "시뮬레이션 설정을 먼저 완료해주세요"
          }
          disabled={true}
          sx={{
            backgroundColor: !isSimulationReady ? '#f5f5f5' : 'white',
            mt: 3,
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              opacity: 1
            }
          }}
        />
        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{ mt: 2 }}
          disabled={!auth.currentUser?.emailVerified || !isSimulationReady || isSimulationLoading}
        >
          {!auth.currentUser ? (
            '로그인 후 이용하실 수 있습니다'
          ) : !auth.currentUser.emailVerified ? (
            '이메일 인증 후 이용하실 수 있습니다'
          ) : isSimulationLoading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} color="inherit" />
              <span>처리 중...</span>
            </Box>
          ) : (
            '결과 확인하기'
          )}
        </Button>
      </form>

      {/* 검색 UI (비제어 방식) */}
      <Box sx={{
        display: 'flex',
        gap: 1,
        mt: 2,
        mb: 3,
        alignItems: 'center'
      }}>
        <TextField
          fullWidth
          size="small"
          placeholder="메시지 검색..."
          inputRef={searchRef}
          defaultValue=""
        />
        <Button
          variant="outlined"
          onClick={handleSearch}
        >
          검색
        </Button>
      </Box>

      <Box sx={{ mt: 3 }}>
        {isSearching ? (
          displayedSearchResults.length === 0 ? (
            <Typography sx={{ textAlign: 'center', color: 'text.secondary', my: 2 }}>
              검색 결과가 없습니다.
            </Typography>
          ) : (
            <>
              {displayedSearchResults.map((message) => (
                <Paper key={message.documentId} sx={{ p: 2, mb: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1, whiteSpace: 'pre-wrap' }}>
                    질문: {message.question}
                  </Typography>

                  <Box sx={{ borderTop: '1px solid #e0e0e0', my: 2 }} />
                  {message.isVoteSimulation && (
                    <Box sx={{
                      width: '100%',
                      minHeight: '300px',
                      overflowX: 'auto',
                      mb: 3
                    }}>
                      <Box sx={{
                        width: '100%',
                        height: '100%'
                      }}>
                        <VoteVisualization
                          gptResponse={message.answer}
                          options={message.simulationOptions || []}
                          agenda={message.question.match(/"([^"]+)"/)?.[1] || ''}
                          displayName={auth.currentUser?.displayName || '익명'}
                        />
                      </Box>
                    </Box>
                  )}

                  <Button
                    onClick={() => setExpandedAnswers(prev => ({
                      ...prev,
                      [message.documentId]: !prev[message.documentId]
                    }))}
                  >
                    {expandedAnswers[message.documentId] ? '답변 접기 ▼' : '답변 보기 ▶'}
                  </Button>

                  <Collapse in={expandedAnswers[message.documentId]} timeout="auto">
                    <Typography variant="body1" sx={{ mb: 1, whiteSpace: 'pre-wrap' }}>
                      답변: {message.answer}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
                      <Button 
                        variant="outlined"
                        onClick={() => {
                          navigator.clipboard.writeText(message.answer);
                          alert('복사되었습니다!');
                        }}
                        sx={{
                          borderColor: 'primary.main',
                          color: 'primary.main',
                          '&:hover': {
                            borderColor: 'primary.dark',
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                          }
                        }}
                      >
                        복사하기
                      </Button>
                    </Box>
                  </Collapse>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Typography variant="caption" color="text.secondary">
                      {message.timestamp && new Date(message.timestamp).toLocaleString()}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <IconButton
                        onClick={() => handleDeleteClick(message)}
                        size="small"
                        sx={{
                          '&:hover': {
                            color: 'error.main'
                          }
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleShare(message)}
                      >
                        자랑하기
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              ))}
              
              {!searchHasMore && displayedSearchResults.length > 0 && (
                <Typography sx={{ textAlign: 'center', py: 2, color: 'text.secondary' }}>
                  모든 메시지를 불러왔습니다.
                </Typography>
              )}
            </>
          )
        ) : (
          <>
            {messages.map((message) => (
              <Paper key={message.documentId} sx={{ p: 2, mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1, whiteSpace: 'pre-wrap' }}>
                  질문: {message.question}
                </Typography>

                <Box sx={{ borderTop: '1px solid #e0e0e0', my: 2 }} />
                {message.isVoteSimulation && (
                  <Box sx={{
                    width: '100%',
                    minHeight: '300px',
                    overflowX: 'auto',
                    mb: 3
                  }}>
                    <Box sx={{
                      width: '100%',
                      height: '100%'
                    }}>
                      <VoteVisualization
                        gptResponse={message.answer}
                        options={message.simulationOptions || []}
                        agenda={message.question.match(/"([^"]+)"/)?.[1] || ''}
                        displayName={auth.currentUser?.displayName || '익명'}
                      />
                    </Box>
                  </Box>
                )}

                <Button
                  onClick={() => setExpandedAnswers(prev => ({
                    ...prev,
                    [message.documentId]: !prev[message.documentId]
                  }))}
                >
                  {expandedAnswers[message.documentId] ? '답변 접기 ▼' : '답변 보기 ▶'}
                </Button>

                <Collapse in={expandedAnswers[message.documentId]} timeout="auto">
                  <Typography variant="body1" sx={{ mb: 1, whiteSpace: 'pre-wrap' }}>
                    답변: {message.answer}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
                    <Button 
                      variant="outlined"
                      onClick={() => {
                        navigator.clipboard.writeText(message.answer);
                        alert('복사되었습니다!');
                      }}
                      sx={{
                        borderColor: 'primary.main',
                        color: 'primary.main',
                        '&:hover': {
                          borderColor: 'primary.dark',
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      복사하기
                    </Button>
                  </Box>
                </Collapse>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                  <Typography variant="caption" color="text.secondary">
                    {message.timestamp && new Date(message.timestamp).toLocaleString()}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton
                      onClick={() => handleDeleteClick(message)}
                      size="small"
                      sx={{
                        '&:hover': {
                          color: 'error.main'
                        }
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleShare(message)}
                    >
                      자랑하기
                    </Button>
                  </Box>
                </Box>
              </Paper>
            ))}

            {!hasMore && messages.length > 0 && (
              <Typography sx={{ textAlign: 'center', py: 2, color: 'text.secondary' }}>
                모든 메시지를 불러왔습니다.
              </Typography>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
        disableRestoreFocus
        keepMounted
        onBackdropClick={handleDeleteCancel}
      >
        <DialogTitle id="alert-dialog-title">
          메시지 삭제 확인
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            정말로 이 메시지를 삭제하시겠습니까?
          </DialogContentText>
          {isSharedMessage && (
            <Typography color="warning.main" sx={{ mt: 2 }}>
              ⚠️ 이 메시지는 커뮤니티에 공유된 메시지입니다.
              삭제 시 커뮤니티에서도 함께 삭제됩니다.
            </Typography>
          )}
          <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
            {messageToDelete?.question}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            취소
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            autoFocus
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>

      {showPremiumDialog && (
        <PremiumDialog
          open={showPremiumDialog}
          onClose={() => setShowPremiumDialog(false)}
        />
      )}
    </Box>
  );
}

export default Chat;