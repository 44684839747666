// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.text}>
        사람도 실수하듯, AI도 실수할 수 있습니다.
      </div>
      <div style={{ ...styles.linkContainer, ...styles.text }}>
        <a href="/terms" style={styles.link}>이용약관</a> | 
        <a href="/privacy" style={styles.link}>개인정보처리방침</a> | 
        <a href="/refund" style={styles.link}>환불정책</a>
      </div>
      <div style={styles.text}>
        &copy; 2024 VOTSIM. All rights reserved.
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    width: '100%',
    flexShrink: 0,
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    padding: '30px 0',
    fontSize: '66%',
  },
  text: {
    marginBottom: '10px',
  },
  linkContainer: {
    marginBottom: '10px',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    margin: '0 10px',
    fontSize: '66%',
  }
};

export default Footer;