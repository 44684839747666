import React, { useState, useEffect, useRef } from 'react';
import { Box, Paper, Typography, Grid, Button } from '@mui/material';
import { Camera } from 'lucide-react';
import { createPortal } from 'react-dom';  // 이 줄 추가
import { auth, db } from '../firebase';  // Firebase import 추가
import { doc, updateDoc, increment, setDoc } from 'firebase/firestore';
import { useTheme, useMediaQuery } from '@mui/material';

const parseGptResponse = (response) => {
  try {
    const lines = response.split('\n');
    const results = [];
    
    lines.forEach((line, index) => {
      if (line.includes('ㅣ 성별:')) {
        const infoMatch = line.match(/성별: ([^ㅣ]+).*나이: ([^ㅣ]+).*직업: ([^ㅣ]+).*MBTI: ([^ㅣ]+)/);
        
        let answerMatch = null;
        let reasonMatch = null;

        for (let i = index + 1; i < lines.length && i <= index + 3; i++) {
          const currentLine = lines[i];
          if (currentLine.includes('답변:')) {
            answerMatch = currentLine;
          }
          if (currentLine.includes('이유:')) {
            reasonMatch = currentLine.match(/이유:\s*"([^"]+)"/);
          }
        }

        if (infoMatch && answerMatch) {
          let choice = 1;
          const numberMatch = answerMatch.match(/답변:\s*(\d+)/);
          if (numberMatch) {
            choice = parseInt(numberMatch[1]);
          }
          
          results.push({
            gender: infoMatch[1].trim(),
            age: infoMatch[2].trim(),
            job: infoMatch[3].trim(),
            mbti: infoMatch[4].trim(),
            choice: choice,
            reason: reasonMatch ? reasonMatch[1] : '이유가 명시되지 않았습니다'
          });
        }
      }
    });
    
    return results;
  } catch (error) {
    console.error('Error parsing GPT response:', error);
    return [];
  }
};

const VoterInfoPortal = ({ voter, position, setHoveredPerson }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(hover: none)');

  if (!voter) return null;

  return createPortal(
    <Paper
      sx={{
        position: 'fixed',
        zIndex: 9999,
        width: isMobile ? '90%' : 250,  // 모바일에서는 화면 90% 너비
        p: 2,
        ...(isMobile ? {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',  // 화면 중앙 정렬
          maxHeight: '80vh',  // 최대 높이 제한
          overflowY: 'auto',  // 내용이 많으면 스크롤
        } : {
          top: position.y + 40,
          left: position.x,
          transform: 'translateX(-50%)',
        }),
        boxShadow: 3,
        backgroundColor: 'background.paper',
      }}
    >
      <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
        인적사항
      </Typography>
      <Typography variant="body2">성별: {voter.gender}</Typography>
      <Typography variant="body2">나이: {voter.age}</Typography>
      <Typography variant="body2">직업: {voter.job}</Typography>
      <Typography variant="body2">MBTI: {voter.mbti}</Typography>
      <Typography variant="subtitle2" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>
        선택 이유
      </Typography>
      <Typography variant="body2">{voter.reason}</Typography>
      
      {/* 모바일에서만 확인 버튼 표시 */}
      {isMobile && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            onClick={() => setHoveredPerson(null)}
            sx={{ minWidth: 100 }}
          >
            확인
          </Button>
        </Box>
      )}
    </Paper>,
    document.body
  );
};

const drawVoterDot = (ctx, x, y, size, voter) => {
  // 점 그리기
  ctx.beginPath();
  ctx.arc(x + size/2, y + size/2, size/2, 0, Math.PI * 2);
  ctx.fillStyle = ['남성', '남자', '남'].includes(voter.gender.trim()) ? '#1976d2' : '#dc004e';
  ctx.fill();

  // 성별 문자와 나이
  ctx.fillStyle = '#FFFFFF';
  ctx.font = `bold ${size/2}px Arial`; // 성별 문자 크기 키움
  ctx.textAlign = 'center';
  // 성별 기호 대신 M/W 사용
  const genderText = ['남성', '남자', '남'].includes(voter.gender.trim()) ? 'M' : 'W';
  ctx.fillText(genderText, x + size/2, y + size/1.8 - size/8);
  
  // 나이 텍스트도 굵게
  ctx.font = `bold ${size/2.8}px Arial`;
  ctx.fillText(voter.age.replace(/[^0-9]/g, ''), x + size/2, y + size/2 + size/3);
};

const VoteVisualization = ({ gptResponse, options, agenda, displayName }) => {
  const [parsedData, setParsedData] = useState([]);
  const [hoveredPerson, setHoveredPerson] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState(null);
  const visualizationRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery('(hover: none)');

  useEffect(() => {
    const parsed = parseGptResponse(gptResponse);
    setParsedData(parsed);
  }, [gptResponse]);

  const handleDotInteraction = (voter, event) => {
    if (isMobile) {
      // 모바일에서는 클릭 이벤트로 처리
      const rect = event.currentTarget.getBoundingClientRect();
      setPopoverPosition({
        x: window.innerWidth / 2, // 화면 중앙
        y: window.innerHeight / 2
      });
      setHoveredPerson(voter);
    } else {
      // 데스크톱에서는 호버로 처리
      const rect = event.currentTarget.getBoundingClientRect();
      setPopoverPosition({
        x: rect.left + (rect.width / 2),
        y: rect.top
      });
      setHoveredPerson(voter);
    }
  };

  const saveAsImage = async () => {
    if (!visualizationRef.current) return;

    // 로그인 체크를 먼저 수행
    if (!auth.currentUser) {
      alert('이미지 저장은 로그인 후 이용 가능합니다.');
      return;
    }

    try {
      // 개별 사용자의 카운트 증가
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userDocRef, {
        totalImageSaves: increment(1)
      });

      // statistics 문서 참조
      const statsDocRef = doc(db, 'statistics', 'global');
      
      // 먼저 setDoc으로 문서 생성 시도 (merge: true로 설정하여 기존 문서가 있으면 업데이트)
      await setDoc(statsDocRef, {
        totalImageSaves: increment(1)
      }, { merge: true });

      const canvas = document.createElement('canvas');
      const size = 1080; // 정사각형 이미지
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext('2d');
      const padding = size * 0.05; // 여기로 padding 선언을 옮깁니다

    // 배경
    ctx.fillStyle = '#2A3942';
    ctx.fillRect(0, 0, size, size);

    // 의제 표시
    ctx.fillStyle = '#FFFFFF';
    const maxWidth = size - 100; // 좌우 여백
    let fontSize = 56; // 시작 폰트 크기
    
    // 텍스트가 maxWidth보다 작아질 때까지 폰트 크기 조절
    do {
      ctx.font = `bold ${fontSize}px Arial`;
      const metrics = ctx.measureText(agenda);
      if (metrics.width <= maxWidth) break;
      fontSize -= 2;
    } while (fontSize > 20); // 최소 폰트 크기 제한
    
    ctx.textAlign = 'center';
    ctx.fillText(agenda, size/2, padding + 20);

    // 작성자 정보 추가
    ctx.font = '20px Arial';
    ctx.fillStyle = '#CCCCCC';
    ctx.fillText(`By. ${displayName}`, size/2, padding + 70);

    const groupedVoters = {};
    options.forEach((_, index) => {
      groupedVoters[index + 1] = parsedData.filter(p => p.choice === index + 1);
    });

    // 옵션 그리드 계산
    const columns = options.length > 4 ? 3 : 2;
    const rows = Math.ceil(options.length / columns);
    //const padding = size * 0.05;
    const cellWidth = (size - (padding * 2)) / columns;
    const cellHeight = (size - (padding * 6)) / rows;

    options.forEach((option, index) => {
      const row = Math.floor(index / columns);
      const col = index % columns;
      const x = padding + (col * cellWidth);
      const y = padding * 3 + (row * cellHeight);

// 포스트잇 스타일 배경
const postItX = x + 10;
const postItY = y + 10;
const postItWidth = cellWidth - 20;
const postItHeight = cellHeight - 20;
const foldSize = 80; // 접힌 부분 크기

// 메인 포스트잇 (노란색)
ctx.fillStyle = '#fff8b8';  // 포스트잇 색상
ctx.beginPath();
ctx.moveTo(postItX, postItY);  // 왼쪽 상단에서 시작
ctx.lineTo(postItX + postItWidth, postItY);  // 오른쪽 상단
ctx.lineTo(postItX + postItWidth, postItY + postItHeight);  // 오른쪽 하단
ctx.lineTo(postItX + foldSize, postItY + postItHeight);  // 왼쪽 하단 (접힌 부분 시작)
ctx.lineTo(postItX, postItY + postItHeight - foldSize);  // 접힌 부분 대각선
ctx.closePath();
ctx.fill();

// 접힌 부분 (약간 어두운 노란색)
ctx.fillStyle = '#e8e1a7';  // 접힌 부분의 색상
ctx.beginPath();
ctx.moveTo(postItX, postItY + postItHeight - foldSize);  // 접힌 부분 시작
ctx.lineTo(postItX + foldSize, postItY + postItHeight);  // 접힌 부분 대각선
ctx.lineTo(postItX + foldSize, postItY + postItHeight - foldSize);  // 접힌 부분 닫기
ctx.closePath();
ctx.fill();

      // 옵션 제목
      ctx.fillStyle = '#000000';
      ctx.font = 'bold 48px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(option, x + cellWidth/2, y + 70);

      // 닷보팅 영역
      const voters = groupedVoters[index + 1] || [];
      const dotSize = 50;
      const dotsPerRow = Math.floor((cellWidth - 40) / (dotSize + 5));

      voters.forEach((voter, voterIndex) => {
        const dotRow = Math.floor(voterIndex / dotsPerRow);
        const dotCol = voterIndex % dotsPerRow;
        const dotX = x + 20 + (dotCol * (dotSize + 5));
        const dotY = y + 100 + (dotRow * (dotSize + 5));

        drawVoterDot(ctx, dotX, dotY, dotSize, voter);
      });

      // 투표자 수
      ctx.fillStyle = '#666666';
      ctx.font = '16px Arial';
      ctx.textAlign = 'right';
      ctx.fillText(`총 ${voters.length}명`, x + cellWidth - 20, y + cellHeight - 20);
    });

    // 워터마크
    ctx.fillStyle = '#FFFFFF';
    ctx.font = '48px Arial';
    ctx.textAlign = 'center';
    ctx.fillText('VoteSimulator.com', size/2, size - 40);

      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = agenda + '_' + new Date().toLocaleDateString() + '.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('이미지 저장 중 오류:', error);
      alert('지금은 이미지를 저장할 수 없습니다. 인증 상태를 확인해주세요!');
    }
  };

  const groupedVoters = {};
  options.forEach((_, index) => {
    groupedVoters[index + 1] = parsedData.filter(p => p.choice === index + 1);
  });

  if (!options || options.length === 0) {
    return <Typography>데이터를 불러올 수 없습니다.</Typography>;
  }

  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden'
    }}>
      <Box 
        ref={visualizationRef}
        sx={{ 
          width: '100%',
          maxWidth: '660px',
          bgcolor: '#2A3942',
          borderRadius: 1,
          boxShadow: 1,
          p: 2
        }}
      >
        <Grid 
          container 
          spacing={1}
          sx={{
            width: '100%',
            m: 0,
            justifyContent: 'center'
          }}
        >
          {options.map((option, index) => (
            <Grid 
              item 
              xs={12} 
              sm={6} 
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1
              }}
            >
              <Paper 
                elevation={3}
                sx={{ 
                  width: '100%',
                  maxWidth: '260px',
                  display: 'flex',
                  flexDirection: 'column',
                  p: 1,
                  bgcolor: 'background.paper'
                }}
              >
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {option}
                </Typography>
                
                <Box sx={{ 
                  minHeight: 120,
                  bgcolor: '#e0e0e0',
                  borderRadius: 1,
                  p: 1,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(32px, 1fr))',
                  gap: 0.5
                }}>
                  {groupedVoters[index + 1]?.map((voter, voterIndex) => (
                    <Box
                      key={voterIndex}
                      sx={{ 
                        position: 'relative',
                        width: 32,
                        height: 32
                      }}
                      onClick={(e) => isMobile && handleDotInteraction(voter, e)}
                      onMouseEnter={(e) => !isMobile && handleDotInteraction(voter, e)}
                      onMouseLeave={() => !isMobile && setHoveredPerson(null)}
                    >
                      <Box
                        sx={{ 
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          bgcolor: ['남성', '남자', '남'].includes(voter.gender.trim()) ? 'primary.main' : 'secondary.main',
                          color: 'white',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          fontSize: '0.75rem',
                          '&:hover': {
                            transform: 'scale(1.1)',
                            transition: 'transform 0.2s'
                          },
                          '@media (max-width: 600px)': {
                            '& > span:first-of-type': {
                              marginTop: '-5px'
                            },
                            '& > span:last-of-type': {
                              marginTop: '2px'
                            }
                          },
                          '@media (min-width: 601px) and (max-width: 1194px)': {  // 아이패드 가로/세로 모두 포함
                            '@media (orientation: portrait)': {  // 세로 모드
                              '& > span:first-of-type': {
                                marginTop: '-4px'
                              },
                              '& > span:last-of-type': {
                                marginTop: '2px'
                              }
                            },
                            '@media (orientation: landscape)': {  // 가로 모드
                              '& > span:first-of-type': {
                                marginTop: '-5px'
                              },
                              '& > span:last-of-type': {
                                marginTop: '2px'
                              }
                            }
                          }
                        }}
                      >
                        <span>{['남성', '남자', '남'].includes(voter.gender.trim()) ? '♂' : '♀'}</span>
                        <span style={{ fontSize: '0.8rem' }}>
                          {voter.age.replace(/[^0-9]/g, '')}
                        </span>
                      </Box>
                    </Box>
                  ))}
                </Box>
                
                <Typography sx={{ mt: 1, textAlign: 'right', color: 'text.secondary' }}>
                  총 {groupedVoters[index + 1]?.length || 0}명
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ my: 2 }}>
        <Button
          variant="contained"
          onClick={saveAsImage}
          startIcon={<Camera size={20} />}
          sx={{
            bgcolor: 'primary.main',
            color: 'white',
            '&:hover': {
              bgcolor: 'primary.dark',
            }
          }}
        >
          이미지로 저장
        </Button>
      </Box>

      {hoveredPerson && popoverPosition && (
        <VoterInfoPortal 
          voter={hoveredPerson} 
          position={popoverPosition}
          setHoveredPerson={setHoveredPerson}
        />
      )}
    </Box>
  );
};

export default VoteVisualization;