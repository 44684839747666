// Terms.js
import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
          이용약관
        </Typography>
        
        <Typography paragraph color="text.secondary" sx={{ mb: 4 }}>
          본 약관은 VotSim(이하 "회사")이 제공하는 투표 시뮬레이션 서비스의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.
        </Typography>

        {[
          {
            title: "제1조 (목적)",
            content: `본 약관은 회사가 제공하는 투표 시뮬레이션 서비스 및 관련 제반 서비스의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.`
          },
          {
            title: "제2조 (용어의 정의)",
            content: `본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            1. "서비스"란 회사가 제공하는 투표 시뮬레이션 및 관련 제반 서비스를 의미합니다.
            2. "회원"이란 본 약관에 동의하고 회사와 서비스 이용계약을 체결한 자를 의미합니다.
            3. "무료회원"이란 무료 서비스를 이용하는 회원을 의미합니다.
            4. "유료회원"이란 유료 서비스를 이용하는 회원을 의미합니다.`
          },
          {
            title: "제3조 (서비스의 제공 및 변경)",
            content: `1. 회사는 다음과 같은 서비스를 제공합니다.
               - 투표 시뮬레이션 서비스
               - 커뮤니티 서비스
               - 기타 회사가 추가 개발하거나 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스
            2. 회사는 서비스를 변경하거나 중단할 수 있으며, 이 경우 회원에게 사전 공지합니다.`
          },
          {
            title: "제4조 (서비스 이용료)",
            content: `1. 서비스는 무료 서비스와 유료 서비스로 구분됩니다.
            2. 유료 서비스의 이용 요금 및 결제 방법은 해당 서비스에 명시되어 있는 내용에 따릅니다.
            3. 회사는 유료 서비스 이용요금을 변경할 수 있으며, 변경 시 약관에 따라 공지합니다.`
          },
          {
            title: "제5조 (회원의 의무)",
            content: `회원은 다음 각 호의 행위를 하여서는 안 됩니다.
            1. 회원가입 신청 또는 변경 시 허위내용을 등록하는 행위
            2. 타인의 정보를 도용하는 행위
            3. 회사가 게시한 정보를 변경하는 행위
            4. 회사가 정한 정보 이외의 정보를 송신하거나 게시하는 행위
            5. 회사와 기타 제3자의 저작권 등 지적재산권을 침해하는 행위
            6. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위`
          },
          {
            title: "제6조 (서비스 중단 및 면책)",
            content: `1. 회사는 다음 각 호의 경우 서비스 제공을 중단할 수 있습니다.
               - 설비의 보수 등 공사로 인한 부득이한 경우
               - 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중단한 경우
               - 기타 불가항력적 사유가 있는 경우
            2. 회사는 서비스 중단으로 인한 손해에 대해 배상하지 않습니다.`
          }
        ].map((section, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" fontWeight="bold">
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" style={{ whiteSpace: 'pre-line' }}>
                {section.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Container>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
};

export default TermsOfService;