import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  // Firebase 콘솔에서 가져온 설정값들
  apiKey: "AIzaSyCUJJ6vxieT2fOEldMBd1MaxdHfKkY0zE8",
  authDomain: "votesim-a2fba.firebaseapp.com",
  projectId: "votesim-a2fba",
  storageBucket: "votesim-a2fba.firebasestorage.app",
  messagingSenderId: "228466940440",
  appId: "1:228466940440:web:00977dac86f6a5a1b0016d"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);