import React from 'react';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Navbar from './Navbar';
import Footer from './Footer';

function Layout({ children, user, title, description }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh', // 전체 화면 높이를 차지
      }}
    >
      <Helmet>
        <title>{title ? `${title} - VotSim` : 'VotSim - AI Vote Simulator'}</title>
        <meta name="description" content={description || 'Predict voting results in various situations with our AI-powered vote simulator.'} />
        <meta property="og:title" content={title ? `${title} - VotSim` : 'VotSim - AI Vote Simulator'} />
        <meta property="og:description" content={description || 'Predict voting results in various situations with our AI-powered vote simulator.'} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title ? `${title} - VotSim` : 'VotSim - AI Vote Simulator'} />
        <meta name="twitter:description" content={description || 'Predict voting results in various situations with our AI-powered vote simulator.'} />
      </Helmet>
      <Navbar user={user} />
      <Box
        component="main"
        sx={{
          flexGrow: 1, // 남은 공간을 모두 차지
          width: '100%',
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
}

export default Layout;