// Privacy.js
import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
          개인정보처리방침
        </Typography>
        
        <Typography paragraph color="text.secondary" sx={{ mb: 4 }}>
          VotSim(이하 "회사")은 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
        </Typography>

        {[
          {
            title: "1. 개인정보의 처리 목적",
            content: `회사는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
            - 회원 가입 및 관리
            - 서비스 제공 및 계약의 이행
            - 요금 결제 및 정산
            - 마케팅 및 광고의 활용
            - 투표 시뮬레이션 결과의 저장 및 공유
            - 커뮤니티 서비스 제공 및 관리`
          },
          {
            title: "2. 개인정보의 처리 및 보유 기간",
            content: `회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
            - 회원 가입 정보: 회원 탈퇴 시까지
            - 결제 정보: 관련 법령에 따른 보관 기간
            - 시뮬레이션 데이터: 서비스 제공 기간 동안`
          },
          {
            title: "3. 개인정보의 제3자 제공",
            content: `회사는 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.
            - 이용자가 명시적으로 동의한 경우
            - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우`
          },
          {
            title: "4. 이용자의 권리·의무 및 행사방법",
            content: `이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
            - 개인정보 열람 요구
            - 오류 등이 있을 경우 정정 요구
            - 삭제 요구
            - 처리 정지 요구`
          },
          {
            title: "5. 처리하는 개인정보 항목",
            content: `회사는 다음의 개인정보 항목을 처리하고 있습니다.
            - 필수항목: 이메일 주소, 비밀번호
            - 선택항목: 프로필 정보
            - 자동수집항목: 접속 IP, 쿠키, 서비스 이용 기록`
          },
          {
            title: "6. 개인정보의 파기",
            content: `회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.`
          },
        ].map((section, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" fontWeight="bold">
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" style={{ whiteSpace: 'pre-line' }}>
                {section.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Container>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
};

export default PrivacyPolicy;