import React, { useState } from 'react';
import { auth } from '../firebase';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  sendEmailVerification,
  GoogleAuthProvider,  // 추가
  signInWithPopup,     // 추가
  sendPasswordResetEmail // 추가
} from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, TextField, Button, Typography, Alert, Divider } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';  // 새로 추가
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [showResendButton, setShowResendButton] = useState(false);
  const [resendEmail, setResendEmail] = useState('');

  // 이메일 형식 검증 함수
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // 비밀번호 검증 함수 (최소 6자 이상)
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateEmail(email)) {
      setError('유효한 이메일 주소를 입력해주세요.');
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // 이메일 인증 확인
      if (!userCredential.user.emailVerified) {
        setError('이메일 인증이 필요합니다. 받은편지함을 확인해주세요.');
        setShowResendButton(true);
        setResendEmail(email);
        return;
      }

      // 멤버십 만료 체크 추가
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const now = new Date();
        const expiry = userData.membershipExpiry ? new Date(userData.membershipExpiry) : null;
        const isProActive = userData.membershipType === 'Pro' && expiry && now < expiry;

        if (!isProActive && userData.membershipType === 'Pro') {
          await updateDoc(userDocRef, {
            membershipType: 'Free',
            premiumCredits: 0
          });
        }
      }

      // 이전 페이지 정보가 있으면 해당 페이지로, 없으면 홈으로 이동
      const from = location.state?.from || '/chat';
      navigate(from);
    } catch (error) {
      setError('로그인 실패: ' + error.message);
    }
  };


  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      // 구글 로그인 후 사용자 데이터 Firestore에 저장
      const userDocRef = doc(db, 'users', result.user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        // 새 사용자인 경우 기본 데이터 생성
        await setDoc(userDocRef, {
          displayName: result.user.displayName || '사용자',
          email: result.user.email,
          joinDate: result.user.metadata.creationTime,
          membershipType: 'Free',
          premiumCredits: 0,
          usageHistory: []
        });
      } else {
        // 기존 사용자의 경우 멤버십 만료 체크
        const userData = userDoc.data();
        const now = new Date();
        const expiry = userData.membershipExpiry ? new Date(userData.membershipExpiry) : null;
        const isProActive = userData.membershipType === 'Pro' && expiry && now < expiry;

        if (!isProActive && userData.membershipType === 'Pro') {
          await updateDoc(userDocRef, {
            membershipType: 'Free',
            premiumCredits: 0
          });
        }
      }

      // 이전 페이지 정보가 있으면 해당 페이지로, 없으면 홈으로 이동
      const from = location.state?.from || '/chat';
      navigate(from);
    } catch (error) {
      console.error('Google 로그인 에러:', error);
      setError('Google 로그인 실패: ' + error.message);
    }
  };

  const handleSignUp = async () => {
    setError('');

    if (!validateEmail(email)) {
      setError('유효한 이메일 주소를 입력해주세요.');
      return;
    }

    if (!validatePassword(password)) {
      setError('비밀번호는 최소 6자 이상이어야 합니다.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // 이메일 인증 메일 발송
      await sendEmailVerification(userCredential.user);
      
      setError('이메일 인증 링크를 발송했습니다. 받은편지함을 확인해주세요.');
    } catch (error) {
      setError('회원가입 실패: ' + error.message);
    }
  };

  // 인증 메일 재발송 함수 추가
  const handleResendVerification = async () => {
    try {
      // 현재 로그인된 사용자 정보로 인증 메일 재발송
      const userCredential = await signInWithEmailAndPassword(auth, resendEmail, password);
      await sendEmailVerification(userCredential.user);
      setError('인증 메일이 재발송되었습니다. 받은편지함을 확인해주세요.');
      setShowResendButton(false);
    } catch (error) {
      setError('인증 메일 재발송 실패: ' + error.message);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 5, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        로그인
      </Typography>
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          action={
            showResendButton && (
              <Button
                variant="contained"
                size="small"
                onClick={handleResendVerification}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  color: '#d32f2f',
                  border: '1px solid currentColor',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    border: '1px solid #d32f2f'
                  }
                }}
              >
                인증 메일 재발송
              </Button>
            )
          }
        >
          {error}
        </Alert>
      )}
      <form onSubmit={handleLogin}>
        <TextField
          fullWidth
          margin="normal"
          label="이메일"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error && error.includes('이메일')}
        />
        <TextField
          fullWidth
          margin="normal"
          label="비밀번호"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!error && error.includes('비밀번호')}
        />
        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{ mt: 2 }}
        >
          로그인
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleSignUp}
          sx={{ mt: 1 }}
        >
          회원가입
        </Button>
      </form>

      
<Divider sx={{ my: 3 }}>또는</Divider>
        
<Button
  fullWidth
  variant="outlined"
  onClick={handleGoogleLogin}
  startIcon={<GoogleIcon />}
  sx={{ 
    mt: 1,
    borderColor: '#4285f4',
    color: '#4285f4',
    '&:hover': {
      borderColor: '#4285f4',
      backgroundColor: 'rgba(66, 133, 244, 0.04)'
    }
  }}
>
  Google로 로그인
</Button>
    </Box>
  );
}

export default Login;