import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { Box, Card, CardContent, Typography, Button, TextField, Grid, Alert } from '@mui/material';

function Profile() {
  const [userData, setUserData] = useState({
    displayName: '',
    email: '',
    membershipType: 'Free',
    usageCount: 0,
    premiumCredits: 0,
    joinDate: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (!auth.currentUser) return;

      // 기본 사용자 데이터 설정을 try 블록 밖으로 이동
      const basicUserData = {
        displayName: auth.currentUser.displayName || '사용자',
        email: auth.currentUser.email,
        joinDate: auth.currentUser.metadata.creationTime,
        membershipType: 'Free',
        premiumCredits: 0,
        usageHistory: []
      };

      try {
        // Firestore에서 사용자 문서 가져오기
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          // 사용자 문서가 없으면 새로 생성하고 기본 데이터 표시
          try {
            await setDoc(userDocRef, basicUserData);
            setUserData({
              ...basicUserData,
              remainingUses: 5
            });
          } catch (error) {
            console.error('새 사용자 데이터 생성 실패:', error);
            setUserData({
              ...basicUserData,
              remainingUses: 5
            });
          }
        } else {
          const userData = userDoc.data();
          
          // 24시간 이내 사용량 계산
          const now = new Date();
          const usageHistory = userData.usageHistory || [];
          const recentUsage = usageHistory.filter(usage => {
            const usageTime = new Date(usage);
            return (now - usageTime) < 24 * 60 * 60 * 1000;
          });

          setUserData({
            ...basicUserData,
            membershipType: userData.membershipType || 'Free',
            premiumCredits: userData.premiumCredits || 0,
            remainingUses: 5 - recentUsage.length,
            membershipExpiry: userData.membershipExpiry || null
          });
        }
        
        setNewDisplayName(auth.currentUser.displayName || '');
      } catch (error) {
        console.error('사용자 데이터 로딩 실패:', error);
        setUserData({
          ...basicUserData,
          remainingUses: 5
        });
        setError('프로필 정보를 불러오는데 실패했습니다. 기본 정보를 표시합니다.');
      }
    };

    fetchUserData();
  }, []);

  const handleUpdateDisplayName = async () => {
    try {
      if (!newDisplayName.trim()) {
        setError('이름을 입력해주세요.');
        return;
      }

      // Firebase Authentication 프로필 업데이트
      await updateProfile(auth.currentUser, {
        displayName: newDisplayName
      });

      // Firestore 문서 업데이트
      await updateDoc(doc(db, 'users', auth.currentUser.uid), {
        displayName: newDisplayName
      });

      setUserData(prev => ({ ...prev, displayName: newDisplayName }));
      setIsEditing(false);
      setSuccess('이름이 성공적으로 업데이트되었습니다.');
      
      // 3초 후 성공 메시지 삭제
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      console.error('프로필 업데이트 실패:', error);
      setError('프로필 업데이트에 실패했습니다.');
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', p: 4 }}>
      <Typography variant="h4" gutterBottom>
        내 프로필
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      
      <Grid container spacing={3}>
        {/* 기본 정보 카드 */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                기본 정보
              </Typography>
              <Box sx={{ mt: 2 }}>
                {isEditing ? (
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      label="이름"
                      value={newDisplayName}
                      onChange={(e) => setNewDisplayName(e.target.value)}
                      sx={{ mb: 1 }}
                    />
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button variant="contained" onClick={handleUpdateDisplayName}>
                        저장
                      </Button>
                      <Button variant="outlined" onClick={() => {
                        setIsEditing(false);
                        setNewDisplayName(userData.displayName);
                        setError('');
                      }}>
                        취소
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Typography variant="body1" gutterBottom>
                      이름: {userData.displayName}
                    </Typography>
                    <Button variant="outlined" onClick={() => setIsEditing(true)} sx={{ mb: 2 }}>
                      이름 수정
                    </Button>
                  </>
                )}
                <Typography variant="body1" gutterBottom>
                  이메일: {userData.email}
                </Typography>
                <Typography variant="body1">
                  가입일: {new Date(userData.joinDate).toLocaleDateString('ko-KR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* 멤버십 정보 카드 */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                멤버십 정보
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" gutterBottom>
                  현재 멤버십: {userData.membershipType}
                </Typography>
                
                {userData.membershipType === 'Pro' && userData.membershipExpiry && (
                  <Typography variant="body1" gutterBottom color="text.secondary">
                    멤버십 만료일: {new Date(userData.membershipExpiry).toLocaleDateString('ko-KR', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </Typography>
                )}

                <Typography variant="body1" gutterBottom>
                  남은 프리미엄 크레딧: {userData.premiumCredits}회
                </Typography>

                <Typography variant="body1" gutterBottom>
                  오늘 남은 무료 횟수: {userData.remainingUses}회
                </Typography>

                <Button variant="outlined" onClick={() => window.location.href='/pricing'}>
                  멤버십 변경
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Profile;