import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';
import { Star } from 'lucide-react';

const PremiumDialog = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', pb: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Star size={48} color="#2196f3" fill="#2196f3" />
        </Box>
        {/* variant을 'h5'에서 'div'로 변경하고 스타일로 크기 조정 */}
        <Typography 
          variant="div" 
          sx={{ 
            fontSize: '1.5rem',
            fontWeight: 'bold'
          }}
        >
          프리미엄으로 업그레이드하세요!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" align="center" color="text.secondary" sx={{ mb: 3 }}>
          Pro 멤버십에서는 더 고도화된 AI 모델로<br/>
          100회까지 시뮬레이션이 가능합니다.<br/>
          더 많은 시뮬레이션으로 더 정확한 결과를 확인해보세요.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', gap: 1, px: 3, pb: 3 }}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            navigate('/pricing');
            onClose();
          }}
          sx={{ 
            borderRadius: 2,
            py: 1.5
          }}
        >
          Pro 멤버십 알아보기
        </Button>
        <Button
          fullWidth
          variant="text"
          onClick={onClose}
          sx={{ 
            borderRadius: 2,
            color: 'text.secondary'
          }}
        >
          다음에 하기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PremiumDialog;