import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const RefundPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
          환불 정책
        </Typography>
        
        <Typography paragraph color="text.secondary" sx={{ mb: 4 }}>
          VotSim(이하 "회사")은 유료 서비스 이용과 관련하여 다음과 같은 환불 정책을 운영하고 있습니다.
        </Typography>

        {[
          {
            title: "1. 환불 신청 자격",
            content: `다음의 경우 환불을 신청하실 수 있습니다.
            - 결제 후 서비스를 전혀 이용하지 않은 경우
            - 서비스 오류로 인해 정상적인 서비스 이용이 불가능한 경우
            - 회사의 귀책사유로 인해 서비스 이용에 중대한 장애가 발생한 경우
            - 기타 관련 법령에 따른 청약철회 사유가 발생한 경우`
          },
          {
            title: "2. 환불 신청 기간",
            content: `- 결제 후 7일 이내: 전액 환불 가능
            - 결제 후 7일 초과: 실제 서비스 이용 일수를 차감한 금액 환불
            - 정기결제의 경우: 다음 결제 예정일 이전에 해지 신청 시 다음 달 결제 취소`
          },
          {
            title: "3. 환불 제외 사항",
            content: `다음의 경우에는 환불이 제한될 수 있습니다.
            - 서비스를 실제로 이용한 후 단순 변심인 경우
            - 부정한 방법으로 서비스를 이용한 이력이 있는 경우
            - 관련 법령 및 이용약관을 위반하여 서비스 이용이 정지된 경우
            - 이벤트 혜택이나 프로모션 코드를 사용한 특가 구매의 경우`
          },
          {
            title: "4. 환불 처리 절차",
            content: `1. 환불 신청 방법
            - 고객센터 이메일(votesimulator@gmail.com)로 환불 신청
            - 필수 기재사항: 결제자명, 결제일, 결제금액, 환불사유
            
            2. 환불 처리 기간
            - 환불 신청 접수 후 영업일 기준 3-5일 이내 처리
            - 결제 수단에 따라 금융기관 처리 기간 추가 소요 가능
            
            3. 환불 금액 산정
            - 미사용 기간에 대한 이용료 일할 계산
            - 할인 혜택이 적용된 경우 실제 결제 금액 기준으로 환불`
          },
          {
            title: "5. 특별 환불 정책",
            content: `1. 서비스 중단 시
            - 회사의 사정으로 서비스가 중단되는 경우 잔여 기간에 대한 전액 환불
            
            2. 오결제의 경우
            - 고객의 실수로 인한 중복 결제: 미사용 중복 결제건 전액 환불
            - 시스템 오류로 인한 결제 오류: 전액 환불`
          },
          {
            title: "6. 환불 방법",
            content: `환불은 다음과 같은 방법으로 진행됩니다.
            - 신용카드 결제: 카드 승인 취소
            - 계좌이체: 환불계좌로 이체
            - 기타 결제수단: 각 결제수단별 환불 정책에 따름`
          }
        ].map((section, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" fontWeight="bold">
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" style={{ whiteSpace: 'pre-line' }}>
                {section.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Container>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
};

export default RefundPolicy;