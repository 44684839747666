import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  Container,
  IconButton,
  Collapse,
  Paper,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

function Navbar({ user }) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  const handleMenuToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
      if (isMobile) {
        handleMenuToggle();
      }
    } catch (error) {
      console.error('로그아웃 실패:', error);
    }
  };

  const menuItems = [
    { text: '시뮬레이터', path: '/chat' },
    { text: '커뮤니티', path: '/community' },
    { text: '요금제', path: '/pricing' },
  ];

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      handleMenuToggle();
    }
  };

  const mobileMenu = (
    <Collapse in={mobileOpen} timeout="auto">
      <Paper 
        sx={{
          position: 'absolute',
          width: '50%',
          right: 0,
          zIndex: theme.zIndex.appBar - 1,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          userSelect: 'none',
        }}
      >
        <List>
          {menuItems.map((item) => (
            <ListItem 
              key={item.text}
              onClick={() => handleNavigation(item.path)}
              sx={{ 
                textAlign: 'center',
                userSelect: 'none',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                cursor: 'pointer'
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
          {user ? (
            <>
              <ListItem 
                onClick={() => handleNavigation('/profile')}
                sx={{ 
                  textAlign: 'center',
                  userSelect: 'none',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  cursor: 'pointer'
                }}
              >
                <ListItemText primary="내 정보" />
              </ListItem>
              <ListItem 
                onClick={handleLogout}
                sx={{ 
                  textAlign: 'center',
                  userSelect: 'none',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  cursor: 'pointer'
                }}
              >
                <ListItemText primary="로그아웃" />
              </ListItem>
            </>
          ) : (
            <ListItem 
              onClick={() => handleNavigation('/login')}
              sx={{ 
                textAlign: 'center',
                userSelect: 'none',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                cursor: 'pointer'
              }}
            >
              <ListItemText primary="로그인" />
            </ListItem>
          )}
        </List>
      </Paper>
    </Collapse>
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <AppBar position="static">
        <Container maxWidth="lg">
          <Toolbar sx={{ padding: '0 24px' }}>
            <Button
              onClick={() => handleNavigation('/')}
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: 'inherit',
                padding: 0,
                mr: 4,
              }}
            >
              VotSim
            </Button>

            {isMobile ? (
              <>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleMenuToggle}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Box sx={{ flexGrow: 1 }}>
                  {menuItems.map((item) => (
                    <Button
                      key={item.text}
                      color="inherit"
                      onClick={() => handleNavigation(item.path)}
                    >
                      {item.text}
                    </Button>
                  ))}
                </Box>
                {user ? (
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button 
                      color="inherit" 
                      onClick={() => handleNavigation('/profile')}
                    >
                      내 정보
                    </Button>
                    <Button color="inherit" onClick={handleLogout}>
                      로그아웃
                    </Button>
                  </Box>
                ) : (
                  <Button color="inherit" onClick={() => handleNavigation('/login')}>
                    로그인
                  </Button>
                )}
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {isMobile && mobileMenu}
    </Box>
  );
}

export default Navbar;