import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Chat from './components/Chat';
import Community from './components/Community';
import Layout from './components/Layout'; // Layout import 추가
import Home from './components/Home'; // 추가
import Pricing from './components/Pricing'; // Pricing import 추가
import Terms from './components/Terms'; // Terms 컴포넌트 import
import Privacy from './components/Privacy'; // Privacy 컴포넌트 import
import Profile from './components/Profile'; // Profile 컴포넌트 import 추가
import Refund from './components/Refund'; // Refund 컴포넌트 import 추가

import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const [user] = useAuthState(auth);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Layout 
          user={user}
         // title="VotSim - AI투표 시뮬레이터"
          description="Predict voting results in various situations with our AI-based vote simulator. Analyze and simulate voting results in real-time."
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/community" element={<Community />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/profile" element={
              user ? <Profile /> : <Navigate to="/login" />
            } />
          </Routes>
        </Layout>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;