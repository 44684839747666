import React, { useState, useEffect } from 'react';

function Home() {
  const [leftEye, setLeftEye] = useState(5);
  const [rightEye, setRightEye] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomNum = Math.floor(Math.random() * 11);
      setLeftEye(randomNum);
      setRightEye(10 - randomNum);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.robot}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="500" height="500" fill="#007bff">
          <rect x="10" y="20" width="44" height="30" rx="5" ry="5" fill="#e0e0e0" />
          <circle cx="22" cy="35" r="5" fill="#333" />
          <circle cx="42" cy="35" r="5" fill="#333" />
          <text x="22" y="36" 
                fontSize="6" 
                fill="#007bff" 
                textAnchor="middle" 
                dominantBaseline="middle"
                fontFamily="'Share Tech Mono', monospace"
                fontWeight="bold"
                style={{
                  fontVariantNumeric: 'slashed-zero',
                  letterSpacing: '0.5px'
                }}>
            {leftEye}
          </text>
          <text x="42" y="36" 
                fontSize="6" 
                fill="#ff4444" 
                textAnchor="middle" 
                dominantBaseline="middle"
                fontFamily="'Share Tech Mono', monospace"
                fontWeight="bold"
                style={{
                  fontVariantNumeric: 'slashed-zero',
                  letterSpacing: '0.5px'
                }}>
            {rightEye}
          </text>
          <rect x="28" y="40" width="8" height="6" rx="1" ry="1" fill="#333" />
          
          {/* Animated paper */}
          <g className="animate-paper">
            <path 
              d="M28.5 42.5 
                 l0.5 0.25
                 h6
                 l0.5 -0.25
                 v4
                 l-0.5 0.25
                 h-6
                 l-0.5 -0.25
                 z" 
              fill="white" 
              stroke="#333" 
              strokeWidth="0.3"
            />
            <line x1="29.3" y1="43.2" x2="34.7" y2="43.2" stroke="#333" strokeWidth="0.2" />
            <line x1="29.3" y1="43.8" x2="34.7" y2="43.8" stroke="#333" strokeWidth="0.2" />
            <line x1="29.3" y1="44.4" x2="34.7" y2="44.4" stroke="#333" strokeWidth="0.2" />
            <line x1="29.3" y1="45.0" x2="34.7" y2="45.0" stroke="#333" strokeWidth="0.2" />
            <line x1="29.3" y1="45.6" x2="34.7" y2="45.6" stroke="#333" strokeWidth="0.2" />
          </g>
          
          <rect x="28" y="10" width="8" height="8" rx="1" ry="1" fill="#007bff" />
          <rect x="5" y="30" width="5" height="10" rx="2" ry="2" fill="#007bff" />
          <rect x="54" y="30" width="5" height="10" rx="2" ry="2" fill="#007bff" />
          <rect x="20" y="5" width="6" height="4" rx="1" ry="1" fill="#007bff" />
          <rect x="38" y="5" width="6" height="4" rx="1" ry="1" fill="#007bff" />
        </svg>
      </div>
      <h1 style={styles.heading}>
        <span style={styles.highlight}>Vot</span>e <span style={styles.highlight}>Sim</span>ulator
      </h1>
      <style>
        {`
          @keyframes paperAnimation {
            0% { transform: translateY(0); opacity: 1; }
            45% { transform: translateY(-2px); opacity: 0.8; }
            50% { transform: translateY(-2.5px); opacity: 0; }
            55% { transform: translateY(-2px); opacity: 0.8; }
            100% { transform: translateY(0); opacity: 1; }
          }
          
          .animate-paper {
            animation: paperAnimation 2s infinite;
            transform-origin: center;
            transform-box: fill-box;
          }
        `}
      </style>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh',
    margin: 0,
    overflow: 'hidden',
    gap: 0,
  },
  robot: {
    marginBottom: '-60px',
  },
  heading: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    color: '#333',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: '-20px',
    marginBottom: '-20px',
  },
  highlight: {
    color: '#007bff',
  }
};

export default Home;